<!-- AI绘画 -->

<template>
	<div class="page_container">
		<div class="drawing_coopen" :class="[isCoopen ? 'show' : 'hide']">
			<div class="info_box">
				<div class="title">{{ config.name }} · 创作平台</div>
				<p>每个产品都源于一个有趣的灵魂</p>
			</div>
			<div class="flow_box">
				<div class="list_box" v-if="flowData.length">
					<div class="list list_wrapper" :id="'list_' + (fIndex + 1)" v-for="(fItem, fIndex) in flowData" :key="'flow_' + fIndex">
						<div class="move_box move_content" :id="'move_' + (fIndex + 1)">
							<div class="item" v-for="(item, index) in fItem" :key="'flow_img_' + index" @click="pictureHandle('view', item)">
								<img :src="item.image" alt="" />
								<div class="coverage">
									<div class="c-line-2">{{ item.desc }}</div>
									<div class="count">
										<div class="icon">
											<a-icon type="heart" />
											<span>{{ item.votes }}</span>
										</div>
										<div class="icon">
											<a-icon type="like" />
											<span>{{ item.likes }}</span>
										</div>
										<div class="icon">
											<a-icon type="eye" />
											<span>{{ item.views }}</span>
										</div>
									</div>
									<div class="tools">
										<div class="icon" @click.stop="pictureHandle('vote', item)">
											<a-icon type="heart" :theme="item.is_vote == 0 ? 'outlined' : 'filled'" :style="{ fontSize: '16px', color: item.is_vote == 1 ? '#FF3434' : '#c4d9f3' }" />
										</div>
										<div class="icon" @click.stop="pictureHandle('like', item)">
											<a-icon type="like" :theme="item.is_like == 0 ? 'outlined' : 'filled'" :style="{ fontSize: '16px', color: item.is_like == 1 ? '#FF3434' : '#c4d9f3' }" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="move_box fakeMove_content" :id="'fakeMove_' + (fIndex + 1)"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="drawing_atlas" id="drawing_atlas" :class="[!isCoopen ? 'show' : 'hide']" @scroll="scrollView">
			<div class="info_box">
				<div class="title">{{ config.name }} · 创作平台</div>
				<p>
					即刻拥有一款神奇的AI绘画产品，让您不再担心没有艺术天赋！<br />
					只要输入文字或上传图片，它就会自动为您创作出真正美丽的太极拳壁纸、卡通人物、“星空之境”等！<br />
					别犹豫了，这款产品定会是您办公、娱乐和生活的最佳良伴！
				</p>
			</div>
			<div class="tabs_box">
				<div class="tabs" v-for="(item, index) in drawingData" :key="'tabs_' + index" @click="jumpView(item.mode, item.id)">
					<img :src="item.image" alt="" />
					<div class="title">{{ item.title }}</div>
					<p>{{ item.desc }}</p>
				</div>
			</div>
			<div class="atlas_box">
				<div class="list_box">
					<div class="list" v-if="atlasData.length">
						<waterfall :col="4" :gutterWidth="20" :data="atlasData">
							<div class="item" v-for="(item, index) in atlasData" :key="'atlas_img_' + item.id" @click="pictureHandle('view', item)">
								<div class="img">
									<img :src="item.image" alt="" />
									<div class="coverage">
										<div class="c-line-2">{{ item.desc }}</div>
										<div class="time">{{ item.createtime }}</div>
										<div class="tools">
											<div class="icon" @click.stop="pictureHandle('vote', item)">
												<a-icon type="heart" :theme="item.is_vote == 0 ? 'outlined' : 'filled'" :style="{ fontSize: '16px', color: item.is_vote == 1 ? '#FF3434' : '#c4d9f3' }" />
											</div>
											<div class="icon" @click.stop="pictureHandle('like', item)">
												<a-icon type="like" :theme="item.is_like == 0 ? 'outlined' : 'filled'" :style="{ fontSize: '16px', color: item.is_like == 1 ? '#FF3434' : '#c4d9f3' }" />
											</div>
										</div>
									</div>
								</div>
								<div class="infos" v-if="item.image">
									<div class="left">
										<div class="avatar">
											<img :src="item.avatar" alt="" />
										</div>
										<div class="nickname c-line-1">{{ item.nickname }}</div>
									</div>
									<div class="right">
										<div class="icons">
											<a-icon type="heart" />
											<span>{{ item.votes }}</span>
										</div>
										<div class="icons">
											<a-icon type="like" />
											<span>{{ item.likes }}</span>
										</div>
										<div class="icons">
											<a-icon type="eye" />
											<span>{{ item.views }}</span>
										</div>
									</div>
								</div>
							</div>
						</waterfall>
					</div>
				</div>
			</div>
		</div>
		<div class="arrow" v-if="isCoopen" @click="isCoopen = false">
			<a-icon type="down-circle" :style="{ fontSize: '40px', color: 'rgba(255, 255, 255, 0.5)' }" />
		</div>
		<PreviewImage :show="previewImageShow" :data="previewImageData" @close="previewImageShow = false" @update="updateCollectStatus"></PreviewImage>
	</div>
</template>

<script>
	import PreviewImage from "@/components/Custom/PreviewImage.vue"
	import { Debounce, regroup } from "@/utils/tools"
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		components: { PreviewImage },
		data() {
			return {
				isCoopen: true, // 开屏页状态
				isScroll: true, // 滚动条状态
				flowData: [], // 滚动区图片数据
				atlasData: [], // 展览区图片数据
				iconTheme: "outlined", // outlined / filled
				previewImageShow: false,
				previewImageData: {
					id: "",
					desc: "",
					image: "",
					width: "",
					height: "",
					vote: false
				},
				page: 1,
				pagesize: 20,
				drawingData: [{ id: "more", title: "更多产品", desc: "请敬请期待更多功能...", image: require("@assets/icons/tabs3.png") }]
			}
		},
		watch: {
			previewImageShow(newValue, oldValue) {
				newValue && this.$notification.destroy()
			}
		},
		computed: {
			...mapGetters("app", ["config"])
		},
		created() {
			this.getAtlasPhoto("index", 1, 50)
			this.getAtlasPhoto("list", 1, 20)
			this.getDrawingClassify()
		},
		mounted() {
			window.addEventListener("mousewheel", this.handleMousewheel, false)
		},
		beforeDestroy() {
			window.removeEventListener("mousewheel", this.handleMousewheel, false)
		},
		methods: {
			// 更新图片收藏状态
			updateCollectStatus(data) {
				if (this.isCoopen) {
					this.flowData.map(list => {
						list.map(item => {
							if (item.id == data.id) {
								item.is_vote = data.vote
								if (item.is_vote == 0) {
									item.votes = Number(item.votes) - 1
								} else {
									item.votes = Number(item.votes) + 1
								}
							}
						})
					})
				} else {
					this.atlasData.map(item => {
						if (item.id == data.id) {
							item.is_vote = data.vote
							if (item.is_vote == 0) {
								item.votes = Number(item.votes) - 1
							} else {
								item.votes = Number(item.votes) + 1
							}
						}
					})
				}
			},
			// 获取绘图分类
			getDrawingClassify() {
				this.$http("drawing.first").then(res => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							const arr = res.data.map(({ id, title, desc, image, mode }) => ({ id, title, desc, image, mode }))
							this.drawingData.unshift(...arr)
						}
					}
				})
			},
			// 展览图片
			getAtlasPhoto(type, page, pagesize) {
				// 滚动图片
				function scrollPhoto(num, dom1, dom2, dom3) {
					const wrapper = document.getElementById(dom1)
					const content = document.getElementById(dom2)
					const fakeContent = document.getElementById(dom3)
					function roll(t) {
						fakeContent.innerHTML = content.innerHTML
						// 开始无滚动时设为0
						wrapper.scrollTop = 0
						// 设置定时器，参数t用在这为间隔时间（单位毫秒），参数t越小，滚动速度越快
						let timer = setInterval(rollStart, t)
						// 鼠标移入div时暂停滚动
						wrapper.onmouseover = function () {
							clearInterval(timer)
							timer = null
						}
						// 鼠标移出div后继续滚动
						wrapper.onmouseout = function () {
							timer = setInterval(rollStart, t)
						}
					}
					// 开始滚动函数
					function rollStart() {
						if (num % 2 == 0) {
							// 正常滚动不断给scrollTop的值-1, 当滚动高度大于列表内容高度时恢复为0
							if (Math.abs(wrapper.scrollTop) >= content.scrollHeight) {
								wrapper.scrollTop = 0
							} else {
								wrapper.scrollTop-- // 倒序
							}
						} else {
							// 正常滚动不断给scrollTop的值+1, 当滚动高度大于列表内容高度时恢复为0
							if (wrapper.scrollTop >= content.scrollHeight) {
								wrapper.scrollTop = 0
							} else {
								wrapper.scrollTop++ // 正序
							}
						}
					}
					roll(20)
				}

				this.$http("drawing.atlas", { type, page, pagesize }).then(res => {
					if (res.code === 1) {
						if (type === "index") {
							if (res.data && res.data.length) {
								this.flowData = regroup(res.data, 5)
								this.$nextTick(() => {
									const timer = setTimeout(() => {
										for (let index = 1; index <= 5; index++) {
											scrollPhoto(index, `list_${index}`, `move_${index}`, `fakeMove_${index}`)
										}
										clearTimeout(timer)
									}, 0)
								})
							}
						}

						if (type === "list") {
							if (res.data && res.data.length) {
								this.atlasData = this.atlasData.concat(res.data)
							} else {
								this.page--
							}
						}
					}
				})
			},
			// 图片事件处理
			async pictureHandle(type, item) {
				if (!item.id) return
				const result = await this.$http("drawing.collect", { draw_images_id: item.id, type }, false)
				if (type === "view") {
					this.lookImage(item.id, item.image, item.width, item.height, item.desc, item.is_vote)
					if (result.code === 1) {
						item.views = Number(item.views) + 1
					}
				} else if (type === "vote") {
					if (result.code === 1) {
						item.is_vote = item.is_vote == 0 ? 1 : 0
						if (this.tabsId == 2) {
							this.collect.data = this.collect.data.filter(obj => {
								return obj.id !== item.id
							})
						} else {
							if (item.is_vote == 0) {
								item.votes = Number(item.votes) - 1
							} else {
								item.votes = Number(item.votes) + 1
							}
						}
					}
				} else if (type === "like") {
					if (result.code === 1) {
						item.is_like = item.is_like == 0 ? 1 : 0
						if (this.tabsId == 3) {
							this.likes.data = this.likes.data.filter(obj => {
								return obj.id !== item.id
							})
						} else {
							if (item.is_like == 0) {
								item.likes = Number(item.likes) - 1
							} else {
								item.likes = Number(item.likes) + 1
							}
						}
					}
				}
			},
			// 查看图片
			lookImage(id, img, w, h, desc, vote) {
				this.previewImageData = {
					id,
					desc,
					image: img,
					width: w,
					height: h,
					vote
				}
				this.previewImageShow = true
			},
			// 跳转视图
			jumpView(val, id) {
				switch (val) {
					case "text2img":
						this.$router.push("/drawing/canvas?id=" + id)
						break
					case "img2img":
						this.$router.push("/drawing/picture?id=" + id)
						break
					default:
						this.$message.warning("正在开发中，敬请期待")
						break
				}
			},
			// 鼠标滚轮
			handleMousewheel: Debounce(function (e) {
				const direction = e.deltaY > 0 ? "down" : "up"
				if (direction === "down") {
					this.isCoopen && (this.isCoopen = false)
				} else {
					!this.isCoopen && this.isScroll && (this.isCoopen = true)
				}
			}, 20),
			// 滚动条
			scrollView: Debounce(
				function (e) {
					this.isScroll = e.target.scrollTop <= 0 ? true : false
					let scrollTop = e.target.scrollTop
					let clientHeight = e.target.clientHeight
					let scrollHeight = e.target.scrollHeight
					if (scrollTop + clientHeight >= scrollHeight - 50) {
						this.page++
						this.getAtlasPhoto("list", this.page, this.pagesize)
					}
				},
				20,
				false
			)
		}
	}
</script>

<style lang="scss" scoped>
	.page_container {
		height: 0;
		min-height: calc(100% - 24px);
		background-image: url("../assets/imgs/drawing_bg.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		overflow: hidden;
		position: relative;
		color: #fff;
		margin-bottom: 24px;

		.arrow {
			position: absolute;
			bottom: 30px;
			left: 50%;
			z-index: 100;
			transform: translateX(-50%);
			cursor: pointer;

			animation: identifier 3s linear infinite;

			@keyframes identifier {
				0% {
					transform: translateX(-50%) scale(1) translateY(0);
				}

				50% {
					transform: translateX(-50%) scale(1) translateY(10px);
				}

				100% {
					transform: translateX(-50%) scale(1) translateY(0);
				}
			}
		}

		.drawing_coopen {
			width: 100%;
			height: 100%;
			border: 1px solid transparent;
			transition: all 0.5s ease;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			padding-bottom: 20px;

			&.show {
				height: 100%;
				padding-top: 120px;

				.info_box {
					transform: scale(1);
				}
			}

			&.hide {
				height: 0;
				padding-top: 0;
				padding-bottom: 0;

				.info_box {
					transform: scale(0);
				}
			}

			.info_box {
				color: #fff;
				text-align: center;
				transition: all 0.3s ease;
				font-family: "YouSheBiaoTiHei";
				letter-spacing: 2px;

				.title {
					font-size: 50px;
				}

				p {
					letter-spacing: 5px;
					margin-bottom: 0;
				}
			}

			.flow_box {
				margin-top: 100px;
				flex: 1;
				mask-image: linear-gradient(0deg, transparent, #fff 170px);
				-webkit-mask-image: linear-gradient(0deg, transparent, #fff 170px);
				-webkit-mask-composite: source-out;

				.list_box {
					height: 0;
					min-height: 100%;
					display: flex;
					flex-wrap: nowrap;
					justify-content: space-between;
					padding: 0 20px;

					mask-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(10%, rgb(255, 255, 255)), color-stop(90%, rgb(255, 255, 255)), color-stop(100%, rgba(255, 255, 255, 0)));
					-webkit-mask-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(10%, rgb(255, 255, 255)), color-stop(90%, rgb(255, 255, 255)), color-stop(100%, rgba(255, 255, 255, 0)));
					-webkit-mask-composite: source-out;

					.list {
						width: calc((100% - 80px) / 5);
						mask-image: linear-gradient(180deg, transparent, #fff 170px);
						-webkit-mask-image: linear-gradient(180deg, transparent, #fff 170px);
						-webkit-mask-composite: source-out;
						overflow: hidden;
						display: flex;

						&:nth-child(2n - 1) {
							margin-top: 100px;
							flex-direction: column;
						}

						&:nth-child(2n) {
							flex-direction: column-reverse;
						}

						.move_box {
							.item {
								margin-bottom: 20px;
								border-radius: 10px;
								overflow: hidden;
								cursor: pointer;
								position: relative;

								&:hover {
									.coverage {
										display: flex;
									}
								}

								.coverage {
									width: 100%;
									height: 100%;
									position: absolute;
									top: 0;
									left: 0;
									background: rgba($color: #000000, $alpha: 0.3);
									display: none;
									flex-direction: column;
									justify-content: flex-end;
									padding: 10px 10px;
									font-size: 12px;

									.tools {
										position: absolute;
										top: 10px;
										right: 10px;
										display: flex;
										align-items: center;

										.icon {
											width: 30px;
											height: 30px;
											border-radius: 8px;
											background: rgba($color: #000000, $alpha: 0.4);
											margin-left: 10px;
											display: flex;
											align-items: center;
											justify-content: center;

											&:hover {
												background: rgba($color: #000000, $alpha: 0.6);
											}

											&:first-child {
												margin-left: 0;
											}
										}
									}

									.count {
										margin-top: 8px;
										display: flex;
										align-items: center;
										color: #a5adb7;

										.icon {
											display: flex;
											align-items: center;
											margin-right: 8px;

											span {
												margin-left: 4px;
											}
										}
									}
								}

								img {
									width: 100%;
									height: auto;
								}
							}
						}
					}
				}
			}
		}

		.drawing_atlas {
			width: 100%;
			height: 100%;
			padding: 40px 12px;
			overflow: hidden;

			&:hover {
				overflow-y: scroll;
				overflow-x: hidden;
				padding-right: 0;
			}

			&::-webkit-scrollbar {
				width: 12px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 12px;
				border: 4px solid rgba(0, 0, 0, 0);
				box-shadow: 4px 0 0 #a5adb7 inset;
			}

			&::-webkit-scrollbar-thumb:hover {
				box-shadow: 4px 0 0 #4a4a4a inset;
			}

			&.hide {
				.info_box {
					transform: scale(0);
				}
			}

			&.show {
				.info_box {
					transform: scale(1);
				}
			}

			.info_box {
				color: #fff;
				text-align: center;
				transition: all 0.5s ease;
				font-family: "YouSheBiaoTiHei";
				letter-spacing: 2px;

				.title {
					font-size: 50px;
				}
			}

			.tabs_box {
				width: 1100px;
				margin: 20px auto;
				display: flex;
				flex-wrap: wrap;
				font-family: "YouSheBiaoTiHei";

				.tabs {
					width: calc((100% - 80px) / 3);
					border-radius: 8px;
					background: rgba(102, 133, 253, 0.15);
					border: 1px solid rgba(102, 133, 253, 1);
					box-shadow: 0px 0px 3px rgba(66, 66, 220, 0.3);
					backdrop-filter: blur(18px);
					padding: 20px 30px;
					cursor: pointer;
					letter-spacing: 2px;
					margin-right: 40px;
					margin-bottom: 20px;

					&:nth-child(3n) {
						margin-right: 0;
					}

					&:hover {
						background: rgba(102, 133, 253, 0.3);
						box-shadow: 0px 0px 30px rgba(0, 66, 220, 0.3);
					}

					img {
						width: auto;
						height: 40px;
						margin-bottom: 10px;
					}

					.title {
						font-size: 24px;
					}

					p {
						margin-bottom: 0;
					}
				}
			}

			.atlas_box {
				width: 1100px;
				margin: 0 auto;

				.list_box {
					display: flex;
					flex-wrap: nowrap;
					justify-content: space-between;
					.list {
						::v-deep .vue-waterfall-column {
							width: calc((100% - 60px) / 4);
						}

						.item {
							margin-bottom: 20px;

							.img {
								position: relative;
								border-radius: 8px;
								overflow: hidden;
								cursor: pointer;

								&:hover {
									.coverage {
										display: flex;
									}
								}

								img {
									width: 100%;
									height: auto;
									border-radius: 8px;
								}

								.coverage {
									width: 100%;
									height: 100%;
									position: absolute;
									top: 0;
									left: 0;
									background: rgba($color: #000000, $alpha: 0.3);
									display: none;
									flex-direction: column;
									justify-content: flex-end;
									padding: 10px 10px;
									font-size: 12px;
									color: #fff;

									.time {
										color: #a5adb7;
										margin-top: 8px;
									}

									.tools {
										position: absolute;
										top: 10px;
										right: 10px;
										display: flex;
										align-items: center;

										.icon {
											width: 30px;
											height: 30px;
											border-radius: 8px;
											background: rgba($color: #000000, $alpha: 0.4);
											margin-left: 10px;
											display: flex;
											align-items: center;
											justify-content: center;

											&:hover {
												background: rgba($color: #000000, $alpha: 0.6);
											}

											&:first-child {
												margin-left: 0;
											}
										}
									}
								}
							}

							.infos {
								margin-top: 10px;
								display: flex;
								align-items: center;
								justify-content: space-between;
								color: #838fa2;
								font-size: 12px;

								.left {
									display: flex;
									align-items: center;
									.avatar {
										width: 22px;
										height: 22px;
										border-radius: 50%;
										overflow: hidden;
										margin-right: 8px;

										img {
											width: 100%;
											height: 100%;
											object-fit: cover;
										}
									}

									.nickname {
										max-width: 100px;
									}
								}

								.right {
									display: flex;
									align-items: center;

									.icons {
										display: flex;
										align-items: center;
										margin-left: 8px;

										span {
											margin-left: 2px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>
